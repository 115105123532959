///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////

@import './../../../../scss/theme-bootstrap';

.hero-large {
  position: relative;
  &__inner {
    position: relative;
    @include breakpoint($large-up) {
      position: absolute;
      z-index: 3;
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      &-hpos--left {
        #{$ldirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$ldirection}: 50%;
        }
        &.hero-large__inner-margin--45 {
          #{$ldirection}: 45%;
        }
        &.hero-large__inner-margin--40 {
          #{$ldirection}: 40%;
        }
        &.hero-large__inner-margin--35 {
          #{$ldirection}: 35%;
        }
        &.hero-large__inner-margin--30 {
          #{$ldirection}: 30%;
        }
        &.hero-large__inner-margin--25 {
          #{$ldirection}: 26%; // deviate from system to satisfy design requirement
        }
        &.hero-large__inner-margin--20 {
          #{$ldirection}: 20%;
        }
        &.hero-large__inner-margin--15 {
          #{$ldirection}: 15%;
        }
        &.hero-large__inner-margin--10 {
          #{$ldirection}: 10%;
        }
        &.hero-large__inner-margin--5 {
          #{$ldirection}: 5%;
        }
      }
      &-hpos--right {
        #{$rdirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$rdirection}: 50%;
        }
        &.hero-large__inner-margin--45 {
          #{$rdirection}: 45%;
        }
        &.hero-large__inner-margin--40 {
          #{$rdirection}: 40%;
        }
        &.hero-large__inner-margin--35 {
          #{$rdirection}: 35%;
        }
        &.hero-large__inner-margin--30 {
          #{$rdirection}: 30%;
        }
        &.hero-large__inner-margin--25 {
          #{$rdirection}: 26%;
        }
        &.hero-large__inner-margin--20 {
          #{$rdirection}: 20%;
        }
        &.hero-large__inner-margin--15 {
          #{$rdirection}: 15%;
        }
        &.hero-large__inner-margin--10 {
          #{$rdirection}: 10%;
        }
        &.hero-large__inner-margin--5 {
          #{$rdirection}: 5%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .hero-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 10%;
      }
      &-vpos--bottom {
        bottom: 10%;
        top: auto;
      }
    }
  }
  &__content {
    text-align: center;
    padding: 10px;
    @include breakpoint($large-up) {
      padding: 0;
    }
  }
  &__text {
    margin-bottom: 5px;
    > p {
      margin: 0;
      line-height: inherit;
    }
    &--3 {
      @include breakpoint($large-up) {
        font-size: 19px;
      }
    }
    &--logo {
      margin-bottom: 15px;
      img {
        display: inline; // override .slick-slide img
      }
    }
    &.header {
      &--medium {
        @include breakpoint($large-up) {
          font-size: 18px;
        }
        @include breakpoint($xlarge-up) {
          font-size: 22px;
        }
      }
      &--extra-large-alt {
        @include breakpoint($large-up) {
          font-size: 40px;
        }
        @include breakpoint($xlarge-up) {
          font-size: 53px;
        }
      }
    }
  }
  &__cta {
    margin: 10px 0 0 0;
    @include breakpoint($large-up) {
      margin-top: 23px;
    }
    .button {
      @include breakpoint($medium-down) {
        width: 100%;
      }
    }
    &--extole {
      @include swap_direction(margin, 10px 0);
      width: 100%;
      span a {
        text-decoration: none;
        color: $color-white;
      }
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  &__button {
    @include extended-link;
    &.button {
      border-width: 1px;
      color: $color-white;
      &.button--inverted {
        color: $color-black;
      }
      &.button--large {
        font-size: 19px;
      }
    }
  }
  &__image {
    img {
      display: block;
      width: 100%;
    }
  }
  &__extole {
    cursor: pointer;
  }
  .mantle-media-asset {
    display: flex;
    justify-content: center;
    img {
      height: auto;
    }
  }
}
  
.section-bb-thickening {
  .finish-panel-1,
  .finish-panel-2 {
    .hero-large__inner-vpos--bottom {
      bottom: 3%;
    }
  }
}
